import Layout from '../components/layout/LayoutComponent'
import React, { useLayoutEffect, useState } from 'react'
import { ScrollMoves } from "../components/gsapFunctions"
import { Modal } from "../components/ModalComponent"

const IndexPage = () => {

    const [openModal, setOpenModal] = useState(false);
    const [titleModal, setTitleModal] = useState("Envio Exitoso");
    const [contentModal, setContentModal] = useState("El correo se ha enviado correctamente.");

  useLayoutEffect(() => {
    let moves = ScrollMoves();
    return () => {
      moves.forEach((move) => {
        move.scrollTrigger.kill();
      })
    }
  })

  const CorreoRef = React.createRef();
  const NombreRef = React.createRef();
  const AsuntoRef = React.createRef();
  const MensajeRef = React.createRef();

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const sendData = () =>{
    let Correo = CorreoRef.current.value.trim();
    let Nombre = NombreRef.current.value.trim();
    let Asunto = AsuntoRef.current.value.trim();
    let Mensaje = MensajeRef.current.value.trim();

    if(Correo === "" || Nombre === "" || Asunto === ""){
        setTitleModal("Error"); setContentModal("Es necesario que llene el Correo, Nombre y el Asunto.");
        setOpenModal(true);
        return;
    }

    if(!validateEmail(Correo)){
        setTitleModal("Error"); setContentModal("Introduzca un correo valido")
        setOpenModal(true);
        return;
    }

    let data = JSON.stringify({
        Correo,
        Nombre,
        Asunto,
        Mensaje
    });

    
    fetch("https://totalemailservice.azurewebsites.net/api/SendMail",
    {
        headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept"
        },
        method: "POST",
        body: data
    })
    .then(function(res){ 
        console.log(res);
        setTitleModal("Envio Exitoso"); 
        setContentModal("El correo se ha enviado correctamente.");
        setOpenModal(true);
    })
    .catch(function(res){ 
        console.log(res);
        setTitleModal("Algo salio mal"); 
        setContentModal("No se pudo enviar el correo.");
        setOpenModal(true);
    })

    
  }

  const info = [
    {name: "Dirección", content: ["Frente a Hotel HEX, módulo g-v-13, Bello Horizonte, Managua, Nicaragua"]},
    {name: "Correo Electronico", content: ["info@necarmed.net", "nelsonjoseh@hotmail.com"]},
    {name: "Teléfono", content: ["(505) 2249-9092", "(305) 218-3519", "(505) 8998-0057"]},
]

  return (
    // <Layout pageTitle="Inicio" content='' isHome={true}></Layout>
    <Layout pageTitle="Inicio" content='' isHome={true}>
      <>
        <Modal open={openModal} setOpen={setOpenModal} Title={titleModal} Content={contentModal}/>
        <div className='sm:container px-5 md:p-0 w-full flex flex-col bg-primary/0 relative z-10 backdrop-blur-sm mx-auto'>
            <div className='flex flex-col 2xl:mx-64 mx-0 mt-20 bg-white/0 flex-wrap'>
                <h2 className='text-center text-3xl font-bold'>CONTÁCTANOS</h2>
            </div>  
          <div className='flex flex-col xl:flex-row 2xl:mx-64 lg:mx-32 justify-center mx-0 my-20 bg-white/0 flex-wrap'>
            
            <div className='basis-1/3 flex flex-col gap-8 xl:gap-16 p-8 justify-center'>
                {
                    info.map((item, index) => (
                        <InfoContainer key={index} name={item.name} content={item.content}/>
                    ))
                }
            </div>
            {/* <div className='basis-2/3 flex flex-col gap-4 xl:gap-8 p-8 justify-center'>
                <div>
                    <label htmlFor="Correo" className="block text-xl font-medium text-gray-700 pb-2">Correo Electronico*</label>
                    <input ref={CorreoRef} type={'email'} name="Correo" id="Correo" className="text-black block w-full rounded-md border-gray-300 pl-7 pr-12 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Correo Electronico" required/>
                </div>
                <div>
                    <label htmlFor="Nombre" className="block text-xl font-medium text-gray-700 pb-2">Nombre Completo*</label>
                    <input ref={NombreRef} type={'text'} name="Nombre" id="Nombre" className="text-black block w-full rounded-md border-gray-300 pl-7 pr-12 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Nombre Completo" required/>
                </div>
                <div>
                    <label htmlFor="Asunto" className="block text-xl font-medium text-gray-700 pb-2">Asunto*</label>
                    <input ref={AsuntoRef} type={'text'} name="Asunto" id="Asunto" className="text-black block w-full rounded-md border-gray-300 pl-7 pr-12 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Asunto" required/>
                </div>
                <div>
                    <label htmlFor="Mensaje" className="block text-xl font-medium text-gray-700 pb-2">Mensaje</label>
                    <textarea ref={MensajeRef} name="Mensaje" id="Mensaje" className="text-black block w-full rounded-md border-gray-300 pl-7 pr-12 py-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" placeholder="Mensaje"/>
                </div>
                <div>
                    <button onClick={sendData} className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        Enviar Correo
                    </button>
                    {/* <button onClick={sendData} className="group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                        Enviar Correo
                    </button> /}
                </div>
            </div> */}
          </div>
            
        </div>
        
      </>
    </Layout>
  )
}
// Step 3: Export your component
export default IndexPage

function InfoContainer({name, content}){
    return(
        <div className='text-white basis-1/2 xl:basis-1/4 text-center border-gray-dark border rounded-lg p-6 flex flex-col backdrop-blur-sm relative z-20 move-right'>
            <p className='text-secondary font-bold text-lg'>{name}</p>
            <div className="basis-full flex justify-center flex-col">
                {
                    content.map((item, index) => (
                        <p key={index}>{item}</p>
                    ))
                }
            </div>
        </div>
    )
}

export const Head = () => (
  <>
    <title>Contactanos | NecarMed</title>
  </>
)